import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APIURL } from '../config/api.config';

const api = APIURL + "/users"

@Injectable({
    providedIn: 'root'
  })
  export class AuthenticationService {
    url: string;
    
    constructor(private http: HttpClient) { 
    }

    authenticate(user: any) {
      this.url = api + '/authenticate'
        return this.http.post<any>(this.url, user);
    }
}