import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../../shared/services/notification.service';
import { navItems } from '../../_nav';
import { interval } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.css']

})
export class DefaultLayoutComponent implements OnInit {
  public sidebarMinimized = false;
  public navItems = navItems;
  notificationList: any;
  newNotifications: any;
  counter: number;

  constructor(
    private notificationService: NotificationService, private router: Router
  ) { }

  apiTimer: any

  ngOnInit() {
    // this.getAllNotifications();

    // this.apiTimer = setInterval(() => {
    //   this.getAllNotifications()
    // }, 10000);
  }

  getAllNotifications() {
    this.notificationService.getAll().subscribe(
      res => {
        console.log(res.msg);
        this.notificationList = res.msg;
        this.newNotifications = this.notificationList.filter(x => x.isViewed == false)
      })
  }

  logout() {
    localStorage.removeItem('token');
    this.router.navigate(['/login'])
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }
}
