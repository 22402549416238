<div class="app-body background-image">
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-md-8 mx-auto">
          <div class="card-group">
            <div class="card p-4">
              <div class="card-body">
                <form [formGroup]="loginForm">
                  <h1>Login</h1>
                  <p class="text-muted">Sign into Holocare Attendance System</p>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="icon-user"></i></span>
                      
                    </div>
                    <input type="text" class="form-control" placeholder="Username" autocomplete="username"  formControlName="username" >
                  </div>
                  <div class="input-group mb-4">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="icon-lock"></i></span>
                    </div>
                    <input type="password" class="form-control" placeholder="Password" autocomplete="current-password" formControlName="password" >
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <button type="button" (click)="login(loginForm.value)"class="btn color px-4">Login</button>
                    </div>
                    
                  </div>
                  <span class="text-danger">{{error}}</span>
                </form>
              </div>
            </div>
            <div class="card text-white color py-5 d-md-down-none " style="width:44%">
              <div class="card-body text-center">
                <div>
                  <img class="logo" src="../../../assets/img/brand/logo-full.png">
                  <img src="../../../assets/img/holocare/product-group-picture.png">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
