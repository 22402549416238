import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'GenderPipe'})
export class GenderPipe implements PipeTransform {
  transform(gender: number): String {

    var str = '';

    if(gender == 1)
    {
        str = "Male";
    }
    else{
        str = "Female";
    }


    return str;
  }
}