import { NgModule } from '@angular/core';
import { GenderPipe } from './gender.pipe';
import { TimePipe } from './time.pipe';


@NgModule({
   
    declarations: [ TimePipe, GenderPipe ],
    exports: [TimePipe, GenderPipe]
})


export class PipeModule { }
