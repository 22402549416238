import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APIURL } from '../../containers/default-layout/config/api.config';


const api =  APIURL + '/notification'

@Injectable({
    providedIn: 'root'
  })
  export class NotificationService {
    url: string;
    
    constructor(private http: HttpClient) { 
    }
    getAll() {
      this.url = api + '/getAll'
        return this.http.get<any>(this.url);
        
    }
    

    updateAll(data: any) {
        this.url = api + '/update'
          return this.http.post<any>(this.url, data);
          
    }
    f


}
