import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'timeDiff'})
export class TimePipe implements PipeTransform {
  transform(time: number): String {
    var diffInMs = Math.abs(Date.now() - time);
    var rem: number;
    var str = '';
    var days  = '';
    var hours = '';
    var minutes = '';
    var seconds = '';

       if((diffInMs / (1000 * 60 * 60 * 24)) > 1)
       {
       rem = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
       days = (Math.floor(diffInMs / (1000 * 60 * 60 * 24))).toString() + " Days " 
       diffInMs = diffInMs - (rem*1000*60*60*24)
       }
       
       if((diffInMs / (1000 * 60 * 60)) > 1)
       {
       rem = Math.floor(diffInMs / (1000 * 60 * 60));
       hours = (Math.floor(diffInMs / (1000 * 60 * 60 ))).toString()  + " Hours " 
       diffInMs = diffInMs - (rem*1000*60*60)
       }

       
       if((diffInMs / (1000 * 60)) > 1)
       {
       rem = Math.floor(diffInMs / (1000 * 60));
       minutes = (Math.floor(diffInMs / (1000 * 60 ))).toString() + " Minutes "
       diffInMs = diffInMs - (rem*1000*60)
       }

       if((diffInMs / (1000 )) > 1)
       {
       rem = Math.floor(diffInMs / (1000 ));
       seconds = (Math.floor(diffInMs / (1000))).toString() + " Seconds "
       diffInMs = diffInMs - (rem*1000)
       }

       if(days != '')
       str = days + hours + " ago "
       else{
           if(hours != '')
           str = hours + minutes + "ago"
           else
           str = minutes + seconds + "ago"
       }


    return str;
  }
}