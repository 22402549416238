import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
  },
  {
    name: 'Device List',
    url: '/devices',
    icon: 'icon-screen-tablet',

  },
  {
    name: 'Employees',
    url: '/employees',
    icon: 'icon-user',
  },
  {
    name: 'Records',
    url: '/records',
    icon: 'icon-doc',

  },
  {
    name: 'Schedules',
    url: '/schedule',
    icon: 'icon-clock'
  },
  {
    name: 'Roles',
    icon: 'icon-mustache',
    children: [
      {
        name: 'Add a new Role',
        url: '/roles/add',
        icon: 'icon-mustache',
      },
      {
        name: 'All Roles',
        url: '/roles',
        icon: 'icon-mustache',
      }
    ]
  }];
