import { style } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../containers/default-layout/services/authentication.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  error: String;
  constructor(private builder: FormBuilder, private userAuth: AuthenticationService, private router: Router) {
  }

  ngOnInit() {
    this.loginForm = this.builder.group({
      'username': new FormControl('', [Validators.required]),
      'password': new FormControl('', [Validators.required])
    });
  }

  login(user: any) {
    this.userAuth.authenticate(user).subscribe(
      (res) => {
        if (res.sucess) {
          this.router.navigate([''])
          localStorage.setItem('token', res.token);
        }
        else {
          this.error = "Wrong username/password"
          this.loginForm.reset();
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
}
